import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/astro.jpg'
import thumb02 from '../assets/images/thumbs/power.jpg'
import thumb03 from '../assets/images/thumbs/lawn.jpg'
import thumb04 from '../assets/images/thumbs/tree.jpg'
import thumb05 from '../assets/images/thumbs/cobblestones.jpg'
import thumb06 from '../assets/images/thumbs/garden.jpg'

import full01 from '../assets/images/fulls/full-astro.jpg'
import full02 from '../assets/images/fulls/full-power.jpg'
import full03 from '../assets/images/fulls/full-lawn.jpg'
import full04 from '../assets/images/fulls/full-tree.jpg'
import full05 from '../assets/images/fulls/full-cobble.jpg'
import full06 from '../assets/images/fulls/full-garden.jpg'

const DEFAULT_IMAGES = [
    { id: '1', src: full01, thumbnail: thumb01, caption: 'Synthetic grass', description: 'The latest technology synthetic grass with a guarantee. '},
    { id: '2', src: full02, thumbnail: thumb02, caption: 'Powerwashing', description: 'Bring a space back to life by removing years of dirt and buildup. '},
    { id: '3', src: full03, thumbnail: thumb03, caption: 'Lawn replacement', description: 'High quality roll out grass, professionally laid.'},
    { id: '4', src: full04, thumbnail: thumb04, caption: 'Tree and hedge Maintenance', description: 'Professional removal and cleanup of unwanted trees and hedges.'},
    { id: '5', src: full05, thumbnail: thumb05, caption: 'Cobblelock cleaning', description: 'Restore aging driveways and paths to their former glory.'},
    { id: '6', src: full06, thumbnail: thumb06, caption: 'Garden maintenance', description: 'General garden cleanup and maintenance.'}
];

class HomeIndex extends React.Component {

    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0,
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
    }

    openLightbox (index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }
    closeLightbox () {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious () {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext () {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }
    handleClickImage () {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }

    render() {
        const siteTitle = "Mow Plus+"
        const siteDescription = "Gardening and lanscaping services Skerries"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>Welcome to Mow Plus +</h2>
                        </header>
                            <p>We are a gardening and landscaping buisiness based in Skerries, North Co. Dublin. <br/><br/>
                            Mow Plus + Landscaping Gardening & Services is a complete professional landscaping service with a wide-range
                            of experience from large commercial properties to smaller custom jobs. WM Landscaping Gardening & Services
                            provides with a wide range of expert services including lawn care design, maintenance and installation of 
                            everything from astroturf to industrial power washing.<br/><br/>
                            Contact us for a consultation and we will help you in looking after your garden and home. 
                            Our experienced and skilled professionals do the job quickly, safely and with the expertise you need and deserve.<br/><br/>
                            <i>Mick</i> <br />
                            <a href="tel:+353862606664">tel: 086 260 6664</a>
                            </p>

                        <ul className="actions">
                            <li><a href="#three" className="button">Contact Us</a></li>
                        </ul>
                    </section>

                    <section id="two">

                        <Gallery images={DEFAULT_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                            src,
                            thumbnail,
                            caption,
                            description
                        }))} />

                       <h2>Other services</h2>

                        <p>Lawn Cutting | Hedge Trimming | Garden Clean Ups | Leaf Clearance | Weed Clearing | Garden Lighting| Grounds maintenance | Patio cleaning | Deck cleaning </p>

                        <ul className="actions">
                            <li><a href="https://www.facebook.com/pg/Mow-Plus-Garden-Services-352342260188/" className="button">Visit our Facebook for more</a></li>
                        </ul>
                    </section>

                    <section id="three">
                        <h2>Get In Touch</h2>
                        <p>We would love to hear from you, feel free to contact us by any of the below options and we will get back to you as soon as possible.</p>
                        <div className="row">
                            <div className="8u 12u$(small)">
                                <form method="post" action="https://formspree.io/ddools@gmail.com">
                                    <div className="row uniform 50%">
                                        <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                        <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                                        <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                                    </div>
                                    <ul className="actions">
                                        <li><input type="submit" value="Send Message" /></li>
                                    </ul>
                                </form>
                            </div>
                            <div className="4u 12u$(small)">
                                <ul className="labeled-icons">
                                    <li>
                                        <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                        Skerries,<br />
                                        Co. Dublin.
                                    </li>
                                    <li>
                                        <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                        <a href="tel:+353862606664">086 260 6664</a>
                                    </li>
                                    <li>
                                        <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                        <a href="mailto:mowplus@gmail.com?subject=website enquiry">mowplus@gmail.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                </div>

            </Layout>
        )
    }
}

export default HomeIndex